/*
 * Functions & Mixins
 *
 */

// rem calculation

@function rem($n, $cal-font-size: $font-size-base) {
	@return ($n / $cal-font-size) * 1rem;
}

@mixin rem($property, $values) {
	$rem: ();

	@each $value in $values {
		@if $value == 0 or $value == auto {
			$rem: append($rem, $value);
		} @else {
			$rem: append($rem, rem($value));
		}
	}

	#{$property}: $rem;
}

// media queries

@mixin mq-print() {
	@media print {
		@content;
	}
}

@mixin mq-min($width) {
	@media screen and (min-width: $width) {
		@content;
	}
}

@mixin mq-max($width) {
	@media screen and (max-width: $width) {
		@content;
	}
}