$color-accent: #0085ba;
$color-hover: #006799;
$color-discourage: #000;

$break_xs: 480px;

@import '_defaults/_mixins';

.ts-cookie-consent * {

	position: relative;

}

.ts-cookie-consent {

	position: fixed;

	z-index: 100;

	right: 0;
	bottom: 0;
	left: 0;

	.ts-cookie-consent {

		&__banner {

			box-sizing: border-box;

			width: 100%;
			height: 100%;

			padding: 20px 30px;

			background-color: #fff;
			box-shadow: 0 0 0 0 rgba(0,0,0,.1),0 0 10px 0 rgba(0,0,0,.1);

		}

		&__wrapper {

			display: block;

			max-width: 1280px;

			margin-right: auto;
			margin-left: auto;

			@include mq-min( $break_xs ) {

				display: flex;

				align-items: center;

			}

		}

		&__text {

			flex: 1 1 100%;

			padding-bottom: 20px;

			text-align: center;

			font-size: 13px;

			@include mq-min( $break_xs ) {

				padding-right: 20px;
				padding-bottom: 0;

				text-align: left;

			}

			> p + p {

				margin-top: 12px;

			}

			a {
				text-decoration: underline;

				color: $color-accent;

				&:hover {

					color: $color-hover;

				}

			}

			&--hide-on-mobile {

				display: none;

				@include mq-min( $break_xs ) {

					display: block;
				}
			}

			&--hide-on-desktop {

				@include mq-min( $break_xs ) {

					display: none;
				}

			}

		}

		&__btns {

			display: flex;
			flex: 1 1 0%;

			justify-content: center;

		}

		&__btn {

			height: 36px;

			padding-top: 0;
			padding-right: 20px;
			padding-bottom: 0;
			padding-left: 20px;

			transition: background-color, color;
			transition-duration: 200ms, 200ms;
			white-space: nowrap;
			border: none;

			border-radius: 20px;

			background-color: #000;

			font-size: 13px;
			font-weight: 400;
			line-height: 36px;

			&--accept,
			&--save {

				background-color: rgba( $color-accent, 1 );

				&:hover {

					background-color: rgba( $color-hover, 1 );

				}

			}

			&--decline,
			&--edit,
			&--cancel {

				margin-right: 10px;

				color: rgba( $color-discourage, 0.5 );

				background-color: rgba( $color-discourage, 0.05 );

				&:hover {

					color: rgba( $color-discourage, 0.6 );
					background-color: rgba( $color-discourage, 0.1 );

				}

			}

		}

		&__toggle {

			height: auto;

			padding: 0;
			padding-left: 20px;

			color: $color-accent;

			border: none;

			background-color: transparent;

			font-size: 13px;
			font-weight: 400;

			line-height: inherit;

			&::after,
			&::before {

				position: absolute;

				top: 50%;
				left: 0;

				display: block;

				width: 9px;
				height: 1px;

				content: '';
				transition-timing-function: ease-out;
				transition-duration: 150ms;

				transition-property: transform;

				background-color: $color-accent;

			}

			&::before {

				transform: rotate( 90deg );

			}

		}

		&__modal {

			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;

			display: flex;

			padding: 24px;

			transition: all 500ms cubic-bezier(.175,.885,.32,1.275);

			transform: translate( 0, 100% );

			opacity: 0;
		}

		&__holder {

			display: flex;

			flex: 1 0 auto;

			flex-direction: column;

			width: 80%;

			max-width: 500px;

			max-height: 80%;

			margin: auto;

			padding: 20px 0;

			background-color: #fff;

			box-shadow: 0 0 36px rgba( black, .25 );

		}

		&__form {

			li + li {

				padding-top: 12px;

				border-top: 1px solid #ccc;

			}

			label {

				display: block;

				padding-top: 12px;

				padding-right: 72px;

				font-weight: 400;

			}

			.ts-cookie-option__switch {

				position: absolute;

				top: 15px;
				right: 0;

			}

		}

		&__option {

			padding-right: 20px;
			padding-bottom: 12px;
			padding-left: 20px;

			@include mq-min( $break_xs ) {

				padding-right: 30px;
				padding-left: 30px;

			}

		}

		&__option-list {

			overflow: auto;

			margin: 0;

			list-style: none;

		}

		&__option-text {

			margin: 0;

		}

		&__option-title {

			font-weight: 700;

		}

		&__modal-text {

			font-size: 13px;

		}

		&__actions {

			display: flex;

			width: 100%;
			padding-top: 20px;

			padding-right: 20px;
			padding-left: 20px;

			border-top: 1px solid #ccc;

			justify-content: center;

			@include mq-min( $break_xs ) {

				justify-content: left;

				padding-right: 30px;
				padding-left: 30px;

			}

		}


	}

	&--top,
	&--top-left,
	&--top-right {

		top: 0;
		bottom: auto;

	}

	&--left,
	&--right,
	&--top-left,
	&--top-right,
	&--bottom-left,
	&--bottom-right,
	&--left-float,
	&--right-float {

		@include mq-min( $break_xs ) {

			max-width: 300px;

		}

		.ts-cookie-consent {

			&__wrapper {

				display: block;

			}

			&__text {

				padding-right: 0;
				padding-bottom: 20px;

				text-align: center;

			}

		}

	}

	&--left,
	&--right {

		@include mq-min( $break_xs ) {

			top: 0;
			bottom: 0;

		}

	}

	&--left {

		@include mq-min( $break_xs ) {

			right: auto;

		}

	}

	&--right {

		@include mq-min( $break_xs ) {

			left: auto;

		}

	}

	&--top-left {

		@include mq-min( $break_xs ) {

			top: 20px;
			right: auto;
			bottom: auto;
			left: 20px;

		}

	}

	&--top-right {

		@include mq-min( $break_xs ) {

			top: 20px;
			right: 20px;
			bottom: auto;
			left: auto;

		}

	}

	&--bottom-left {

		@include mq-min( $break_xs ) {

			right: auto;
			bottom: 20px;
			left: 20px;

		}

	}

	&--bottom-right {

		@include mq-min( $break_xs ) {

			right: 20px;
			bottom: 20px;
			left: auto;

		}

	}

	&--top-float,
	&--bottom-float {

		right: 20px;
		left: 20px;

	}

	&--top-float {

		top: 20px;
		bottom: auto;

	}

	&--bottom-float {

		top: auto;
		bottom: 20px;

	}

	&--left-float,
	&--right-float {

		top: 20px;
		bottom: 20px;

		@include mq-min( $break_xs ) {

			max-width: 300px;

		}

	}

	&--left-float {

		right: auto;
		left: 20px;

	}

	&--right-float {

		right: 20px;
		left: auto;

	}

	&--center-center {

		top: 50%;
		right: auto;
		bottom: auto;
		left: 50%;

		transform: translate( -50%, -50% );

		.ts-cookie-consent {

			&__wrapper {

				flex-direction: column;

			}


			&__text > * {

				text-align: center;
			}

			&__btns {

				margin-top: 20px;
			}
		}
	}

	&--modal-open {

		.ts-cookie-consent {

			&__modal {

				transform: translate( 0, 0 );

				opacity: 1;
			}

		}

	}

}

.ts-cookie-option {

	&__switch {

		position: relative;

		display: inline-block;

		width: 40px;
		height: 20px;

		.switch{

			&__slider {

				position: absolute;

				top: 0;
				right: 0;
				bottom: 0;
				left: 0;

				cursor: pointer;

				transition: 400ms;

				border-radius: 20px;

				background-color: #ccc;

				font-size: 0;

				&::before {

					position: absolute;

					bottom: 2px;
					left: 2px;

					width: 16px;

					height: 16px;

					content: '';

					transition: 400ms;

					border-radius: 50%;

					background-color: white;

				}

			}

			&__input {

				visibility: hidden;

				&:checked + .switch__slider {

					background-color: $color-accent;

					&::before {

						transform: translateX( 20px );

					}

				}

				&:disabled + .switch__slider {

					cursor: not-allowed;

				}

			}

		}

	}

	&__details {

		overflow: hidden;

		max-height: 0;

		transition-timing-function: ease-out;
		transition-duration: 1000ms;
		transition-property: max-height;

	}

	&__detail {

		margin-top: 10px;

	}

	&--details-open {

		.ts-cookie-option__details {

			max-height: 1000px;

		}

		.ts-cookie-consent__toggle {

			&:after {

				transform: rotate( 180deg );

			}

			&:before {

				transform: rotate( 360deg );

			}

		}

	}

}

.ts-cookie-policy {

	padding: 20px;

	background-color: rgba( $color-discourage, 0.05 );

	font-size: 13px;

	.cookie-policy {

		&__title {

			margin-bottom: 10px;

		}

		&__desc,
		&__link {

			margin-bottom: 0;

		}

		&__link {

			display: block;

			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			color: $color-accent;

		}

	}

}

.ts-cookie-placeholder {

	position: relative;

	.placeholder {

		&__content {

			position: absolute;

			top: 0;
			left: 0;

			display: flex;

			width: 100%;
			height: 100%;

			padding: 30px;

			text-align: center;

			background-color: #eee;

			align-items: center;
			justify-content: center;

		}

	}

}